<template>
  <div id="slide-zip-code" class="w-100">
    <div class="row">
      <div class="col-md-5 zipCode-iconContainer">
        <p
          class="text-center display-1"
          :class="{'text-muted': !addressFormValid, 'text-primary': addressFormValid}"
        >
          <i class="fal fa-map-marker-alt"></i>
        </p>
      </div>
      <div class="col-md">
        <div class="form-group d-flex">
          <div class="mr-2 w-100">
            <label>{{ $t("address.street") }}</label>
            <input
              ref="street"
              v-model="street"
              placeholder="Musterstraße"
              class="form-control"
              @keypress.enter="next"
            />
          </div>
          <div style="width: 100px;">
            <label>{{ $t("address.houseNumber") }}</label>
            <input
              ref="houseNumber"
              v-model="houseNumber"
              maxlength="5"
              placeholder="12"
              class="form-control"
              @keypress.enter="next"
            />
          </div>
        </div>
        <div class="form-group d-flex">
          <div class="mr-2 w-100">
            <label>{{ $t("address.zipCode") }}</label>
            <input
              ref="zipCode"
              type="tel"
              v-model="zipCode"
              minlength="4"
              maxlength="5"
              placeholder="12345"
              class="form-control"
              @keypress.enter="next"
            />
          </div>
          <div class="w-100">
            <label>{{ $t("address.city") }}</label>
            <input
              ref="city"
              v-model="city"
              placeholder="Berlin"
              class="form-control"
              @keypress.enter="next"
            />
          </div>
        </div>
        <p class="footnote mt-2">{{ $t("address.info") }}</p>
        <button :disabled="!addressFormValid" class="btn btn-primary w-100" @click="next">
          {{ $t("address.next") }}
          <i class="fal fa-arrow-right ml-2"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    next() {
      if (this.addressFormValid) {
        this.$store.commit("nextStep");
      }
    }
  },
  computed: {
    street: {
      get() {
        return this.$store.state.street;
      },
      set(value) {
        this.$store.commit("updateStreet", value);
      }
    },
    houseNumber: {
      get() {
        return this.$store.state.houseNumber;
      },
      set(value) {
        this.$store.commit("updateHouseNumber", value);
      }
    },
    zipCode: {
      get() {
        return this.$store.state.zipCode;
      },
      set(value) {
        this.$store.commit("updateZipCode", value);
      }
    },
    city: {
      get() {
        return this.$store.state.city;
      },
      set(value) {
        this.$store.commit("updateCity", value);
      }
    },
    countryCode: {
      get() {
        return this.$store.state.countryCode;
      },
      set(value) {
        this.$store.commit("updateCountryCode", value);
      }
    },
    addressFormValid() {
      return (
        this.zipCode &&
        this.zipCode.length >= 4 &&
        this.street &&
        this.houseNumber &&
        this.city
      );
    }
  },
  mounted() {
    this.$refs.street && this.$refs.street.focus();

    this.autocomplete = new google.maps.places.Autocomplete(this.$refs.street, {
      types: ["address"]
    });

    const placesNamesMapping = {
      route: "street",
      street_number: "houseNumber",
      postal_code: "zipCode",
      locality: "city"
    };

    this.autocomplete.addListener("place_changed", () => {
      const place = this.autocomplete.getPlace();
      this.street = '';
      this.houseNumber = '';
      this.zipCode = '';
      this.locality = '';
      this.countryCode = '';

      place.address_components.forEach(component => {
        const type = component.types[0];
        if (placesNamesMapping[type]) {
          this[placesNamesMapping[type]] = component.long_name;
        } else if (type == 'country') {
          this.countryCode = component.short_name;
        }
        // console.log(`component ${type}`, component);
      });

      this.$refs.houseNumber.focus();
    });
  }
};
</script>

<style>
.zipCode-iconContainer {
  display: none;
}

@media (min-width: 768px) {
  .zipCode-iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
