<template>
  <div id="slide-success" v-if="$store.state.valuationConfidence">
    <div class="priceRange-container">
      <div>
        <div class="priceRange">
          <h4 class="priceRangeTitle">{{ $t('success.priceRangeTitle') }}</h4>
        </div>
        <div class="d-flex justify-content-between">
          <div>
            <span>{{ $t('success.lowerRange') }}</span>
            <br />
            <strong class="text-success">{{ $store.state.valuationLowerRange | currency($store.state.valuationCurrency) }}</strong>
          </div>
          <div class="text-right">
            <span>{{ $t('success.upperRange') }}</span>
            <br />
            <strong class="text-success">{{ $store.state.valuationUpperRange | currency($store.state.valuationCurrency) }}</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4 footnote">
      <p class="text-muted" v-html="$t('success.footnote')"></p>
    </div>
  </div>
  <div
    id="slide-success"
    class="text-center"
    v-else
    v-html="$t('success.noEstimate', {name: $store.state.shopInfo.name})"
  ></div>
</template>

<script>
import Mustache from "mustache";

const CONFIDENCE_MAPPING = {
  poor: "Niedrig",
  medium: "Mittel",
  good: "Hoch"
};

export default {
  filters: {
    currency(value, currency) {
      const numberFormat = currency == 'CHF' ? 'de-CH' : 'de-DE';

      return Intl.NumberFormat(numberFormat, {
        style: "currency",
        currency: currency || "EUR",
        minimumFractionDigits: 0
      }).format(value);
    }
  },
  computed: {
    // successText() {
    //   const template = this.$store.state.shopInfo.immoValuationSuccessTemplate;

    //   return Mustache.render(template, {
    //     preis_von: this.$options.filters.currency(
    //       this.$store.state.valuationLowerRange
    //     ),
    //     preis_bis: this.$options.filters.currency(
    //       this.$store.state.valuationUpperRange
    //     ),
    //     confidence: this.confidence
    //   });
    // },
    immoValuationBroker() {
      return this.$store.state.shopInfo.immoValuationBroker;
    },
    confidence() {
      return CONFIDENCE_MAPPING[this.$store.state.valuationConfidence];
    }
  }
};
</script>

<style scoped>
#slide-success {
  flex: 1;
}
.priceRange {
  height: 75px;
  position: relative;
  margin-bottom: 0.5rem;
}
.priceRangeTitle {
  background-color: #e98b27;
  padding: 1rem 1.5rem;
  color: white;
  font-size: 1rem;
  text-align: center;
  font-weight: 400;
}
.priceRangeTitle::before {
  content: "";
  height: 100%;
  width: 2px;
  background-color: #f0c270;
  position: absolute;
  left: 0;
  top: 0;
}
.priceRangeTitle::after {
  content: "";
  height: 100%;
  width: 2px;
  background-color: #f0c270;
  position: absolute;
  right: 0;
  top: 0;
}

@media (min-width: 768px) {
  .priceRange-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .priceRangeTitle {
    font-size: 1.25rem;
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
</style>
