<template>
  <option-buttons fieldName="rsType" :choices-filter="choicesFilter" />
</template>

<script>
export default {
  methods: {
    choicesFilter(choice) {
      const propertyTypes = this.$store.state.shopInfo && this.$store.state.shopInfo.immoValuationPropertyTypes
      return !propertyTypes || propertyTypes.length <= 0 || propertyTypes.includes(choice[0])
    }
  }
}
</script>
