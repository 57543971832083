module.exports = {
  en: {
    site: {
      error: "Page not available."
    },
    titles: {
      "rs-type": "Which property would you like to rate?",
      "house-type": "Please choose the type of house",
      "apartment-type": "Please choose the type of apartment",
      "commercial-type": "Choose the type of commercial property",
      "living-space-house": "How big is the living area of the house?",
      "living-space-apartment": "How big is the living space of the apartment?",
      "plot-area": "How big is the plot area?",
      rooms: "How many rooms are there?",
      "net-floor-space": "Which usable area does the property have?",
      "plot-developed": "Is the property developed?",
      "construction-year": "When was the property built?",
      furnishing: "Which features does the property have?",
      condition: "What is the condition of the property?",
      rented: "What is the status of the property?",
      reason: "What do you need the property valuation for?",
      "zip-code": "Where is your property located?",
      "fake-loading": "Your property valuation is created",
      contact: "Your property valuation is created",
      success: "Result of the online property valuation"
    },
    address: {
      street: "Street",
      houseNumber: "Nr.",
      zipCode: "Zip code",
      city: "City",
      next: "Next",
      info:
        "We need the exact address to better determine the value of your property."
    },
    contact: {
      headline: "Who should receive your <b>property valuation</b>?",
      salutationMr: "Mr",
      salutationMs: "Ms",
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      phone: "Phone number",
      submit: "Receive valuation",
      error: "Something has gone wrong unfortunately.",
      privacyInfo:
        "By providing my data, I agree to be contacted by {name}, see",
      privacy: "Privacy Policy",
      consent: "Consent form"
    },
    success: {
      priceRangeTitle: "Estimated price range of the property",
      lowerRange: "Lower estimate",
      upperRange: "Upper estimate",
      footnote:
        "This market analysis gives you a first impression of the current market value of your property. The rating is based on data similar to offered properties in your area. <br> Since every property is unique, I recommend you additionally an on-site evaluation. We are happy to assist you.",
      noEstimate:
        "<p>Thank you very much for the information!</p><p>One of our colleagues will contact you shortly to give you the most accurate valuation possible for your property.</p><p>With best regards<br>{name}</p>"
    },
    labels: {
      enter: "Or enter",
      next: "Next"
    },

    rsType: {
      trade_site: "Trade site",
      house: "House",
      apartment: "Apartment",
      commercial: "Commercial"
    },
    apartmentType: {
      ground_floor: "Ground floor",
      apartment: "Apartment",
      roof_storey: "Attic flat",
      half_basement: "Souterrain",
      loft: "Loft",
      maisonette: "Maisonette"
    },
    houseType: {
      single_family_house: "Single family house",
      multi_family_house: "Multi family house",
      terrace_house: "Terrace house",
      semidetached_house: "Semidetached house"
    },
    commercialType: {
      living_business: "Living business",
      office: "Office",
      industry: "Industry",
      other_commercial: "Other"
    },
    plotDeveloped: {
      undeveloped: "Undeveloped",
      partially_developed: "Partially developed",
      developed: "Developed"
    },
    furnishing: {
      cellar: "Cellar",
      balcony: "Balcony",
      lift: "Lift",
      garden: "Garden",
      parking_space: "Parking space",
      guest_toilet: "Guest toilet"
    },
    condition: {
      need_of_renovation: "Renovation needed",
      well_kept: "Well maintained",
      mint_condition: "New/Recently renovated"
    },
    rented: {
      rented: "Rented",
      vacant: "Vacant"
    },
    reason: {
      sell: "Sell",
      buy: "Buy",
      other: "Other"
    }
  },
  de: {
    site: {
      error: "Die Seite ist leider aktuell nicht verfügbar."
    },
    titles: {
      "rs-type": "Welche Immobilie möchten Sie bewerten?",
      "house-type": "Bitte wählen Sie die Art des Hauses",
      "apartment-type": "Bitte wählen Sie die Art der Wohnung",
      "commercial-type": "Wählen Sie die Art der Gewerbeimmobilie",
      "living-space-house": "Wie groß ist die Wohnfläche des Hauses?",
      "living-space-apartment": "Wie groß ist die Wohnfläche der Wohnung?",
      "plot-area": "Wie groß ist die Grundstücksfläche?",
      rooms: "Wie viele Zimmer gibt es?",
      "net-floor-space": "Welche Nutzfläche hat die Immobilie?",
      "plot-developed": "Ist das Grundstück erschlossen?",
      "construction-year": "Wann wurde die Immobilie erbaut?",
      furnishing: "Welche Ausstattungsmerkmale besitzt die Immobilie?",
      condition: "Wie ist der Zustand der Immobilie?",
      rented: "Was ist der Status der Immobilie?",
      reason: "Wofür benötigen Sie den Immobilienwert?",
      "zip-code": "Wo befindet sich Ihre Immobilie?",
      "fake-loading": "Ihre Immobilienbewertung wird erstellt",
      contact: "Ihre Immobilienbewertung wird erstellt",
      success: "Resultat der Online-Immobilienbewertung"
    },
    address: {
      street: "Straße",
      houseNumber: "Nr.",
      zipCode: "PLZ",
      city: "Stadt",
      next: "Weiter",
      info:
        "Wir benötigen die genaue Anschrift um den Wert Ihrer Immobilie besser ermitteln zu können."
    },
    contact: {
      headline: "Wer soll Ihre <b>Immobilienbewertung</b> erhalten?",
      salutationMr: "Herr",
      salutationMs: "Frau",
      firstName: "Vorname",
      lastName: "Nachname",
      email: "E-Mail-Adresse",
      phone: "Telefonnummer",
      submit: "Kostenlose Bewertung erhalten",
      error: "Etwas ist beim Abschicken leider schief gelaufen.",
      privacyInfo:
        "Mit der Angabe meiner Daten stimme ich der Kontaktaufnahme durch {name} zu, siehe",
      privacy: "Datenschutzhinweis",
      consent: "Einverständniserklärung"
    },
    success: {
      priceRangeTitle: "Geschätzte Preisspanne des Objektes",
      lowerRange: "Unterer Schätzwert",
      upperRange: "Oberer Schätzwert",
      footnote:
        "Diese Marktanalyse gibt Ihnen einen ersten Eindruck über den aktuellen Marktwert Ihrer Immobilie. Die Bewertung basiert auf Daten vergleichbar angebotener Objekte in Ihrer Umgebung.<br> Da jede Immobilie einzigartig ist, stellen wir Ihnen gerne - nach zusätzlichen Informationen über das Objekt - eine genauere Bewertung zur Verfügung.",
      noEstimate:
        "<p>Vielen Dank für Ihre Angaben!</p><p>In Kürze wird Sie einer unserer Kollegen kontaktieren, um Ihnen eine möglichst genaue Wertermittlung Ihrer Immobilie geben zu können.</p><p>Mit freundlichen Grüßen<br>{name}</p>"
    },
    labels: {
      enter: "Alternativ eingeben",
      next: "Weiter"
    },

    rsType: {
      trade_site: "Grundstück",
      house: "Haus",
      apartment: "Wohnung",
      commercial: "Gewerbe"
    },
    apartmentType: {
      ground_floor: "Erdgeschosswohnung",
      apartment: "Etagenwohnung",
      roof_storey: "Dachgeschosswohnung",
      half_basement: "Souterrain",
      loft: "Loft",
      maisonette: "Maisonette"
    },
    houseType: {
      single_family_house: "Einfamilienhaus",
      multi_family_house: "Mehrfamilienhaus",
      terrace_house: "Reihenhaus",
      semidetached_house: "Doppelhaushälfte"
    },
    commercialType: {
      living_business: "Wohn- und Geschäftshaus",
      office: "Bürogebäude",
      industry: "Industrie- oder Geschäftshaus",
      other_commercial: "Sonstiges"
    },
    plotDeveloped: {
      undeveloped: "Unerschlossen",
      partially_developed: "Teilerschlossen",
      developed: "Vollerschlossen"
    },
    furnishing: {
      cellar: "Keller",
      balcony: "Balkon",
      lift: "Aufzug",
      garden: "Garten",
      parking_space: "Parkplatz",
      guest_toilet: "Gäste-WC"
    },
    condition: {
      need_of_renovation: "Renovierungsbedürftig",
      well_kept: "Gepflegt",
      mint_condition: "Neu/vor kurzem renoviert"
    },
    rented: {
      rented: "Vermietet",
      vacant: "Bezugsfrei"
    },
    reason: {
      sell: "Verkauf",
      buy: "Kauf",
      other: "Sonstiges"
    }
  }
};
