<template>
  <div class="optionButtons" :class="buttonsClass">
    <option-button
      v-for="choice in choices"
      :key="choice[0]"
      @click="clicked(choice[0])"
      :title="choice[1]"
      :name="choice[0]"
      :selected="isSelected(choice[0])"
      :svg="$options.svgs[choice[0]]"
    ></option-button>
  </div>
</template>

<script>
import FIELDS_DATA from "../fields";

export default {
  props: {
    fieldName: {
      type: String,
      required: true
    },
    choicesFilter: {
      type: Function,
      required: false
    },
    onClick: {
      type: Function,
      required: false
    }
  },

  svgs: FIELDS_DATA.svgs,

  methods: {
    clicked(value) {
      if (this.onClick) return this.onClick(value);

      this.$store.commit("setValue", { fieldName: this.fieldName, value });
      this.$store.commit("nextStep");
    },
    isSelected(value) {
      if (Array.isArray(this.field)) {
        return this.field.includes(value);
      } else {
        return this.field == value;
      }
    }
  },
  computed: {
    choices() {
      let fields = this.$t(this.fieldName);
      fields = Object.keys(fields).reduce((acc, field_key) => {
        acc.push([field_key, fields[field_key]]);
        return acc;
      }, []);

      if (this.choicesFilter) return fields.filter(this.choicesFilter);
      return fields;
    },
    field() {
      return this.$store.state[this.fieldName];
    },
    buttonsClass() {
      return `optionButtons--${this.choices.length}`;
    }
  }
};
</script>

<style>
.optionButtons {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin: 0 -10px;
}
</style>