<template>
  <div class="optionButton-container">
    <div class="optionButton" @click.prevent="$emit('click')" :class="{'active': selected}">
      <div class="optionButton-iconContainer">
        <div class="optionButton-icon" v-html="svg || $options.svgString"></div>
      </div>
      <div class="optionButton-title">{{ title }}</div>
    </div>
  </div>
</template>

<script>
import sampleSVG from "../../assets/icons/house.svg";

export default {
  svgString: sampleSVG,
  props: {
    title: {
      required: true,
      type: String
    },
    selected: {
      required: false,
      type: Boolean
    },
    svg: {
      required: false,
      type: String
    }
  }
};
</script>

<style>
.optionButton-container {
  flex: 1 0;
  padding: 10px;
  display: flex;
  height: 185px;
}
.optionButton {
  align-items: center;
  background-color: #fff;
  border: 4px solid #fff;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  -webkit-box-pack: center;
  justify-content: center;
  outline: none;
  width: 100%;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.16);
  cursor: pointer;
}
.optionButton:hover {
  background-color: #f3f3f3;
}
.optionButton.active {
  border-color: var(--primary);
  background-color: #f3f3f3;
}
.optionButton-iconContainer {
  width: 100%;
  height: 60%;
}
.optionButton-title {
  margin-top: 0.25rem;
  max-width: 100%;
  text-align: center;
  font-size: 0.7rem;
  overflow: hidden;
}
.optionButton-icon {
  max-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
}
.optionButton-icon svg {
  flex: 1;
  width: 100%;
  height: 100%;
}

.optionButtons--3 .optionButton-container {
  flex-basis: 100%;
  height: 120px;
}

.optionButtons--4 .optionButton-container {
  flex-basis: 50%;
  height: 120px;
}
.optionButtons--6 .optionButton-container {
  flex-basis: 50%;
  height: 100px;
  width: 50%;
}

@media (min-width: 768px) {
  .optionButton-title {
    font-size: 1rem;
  }
  .optionButtons--3 .optionButton-container {
    flex-basis: 33.333333%;
    height: 185px;
  }
  .optionButtons--4 .optionButton-container {
    flex-basis: 25%;
    height: 185px;
  }
  .optionButtons--6 .optionButton-container {
    width: 33%;
    flex-basis: 33%;
    height: 150px;
  }
}
</style>
