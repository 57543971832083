<template>
  <div class="w-100">
    <div class="row">
      <div class="col-md-8 d-flex flex-column justify-content-between">
        <div class="mb-4 mt-5">
          <vue-slider
            v-model="numbersField"
            :dotSize="26"
            :height="5"
            :min="min"
            :max="max"
            tooltip="always"
            :tooltip-formatter="tooltipFormatter"
            :dot-style="{'background-color': 'var(--primary)', 'border-color': 'var(--primary)'}"
            :process-style="{'background-color': 'var(--primary)'}"
          ></vue-slider>
          <div class="d-flex justify-content-between">
            <p class="footnote">&lt; {{tooltipFormatter(min)}}</p>
            <p class="footnote">&gt; {{tooltipFormatter(max)}}</p>
          </div>
        </div>
        <div>
          <label>{{ $t("labels.enter") }}</label>
          <div class="position-relative">
            <input
              ref="field"
              type="number"
              :min="min"
              :max="max"
              v-model="numbersField"
              @keydown.enter="onSubmit"
              class="form-control"
            />
            <span v-if="unit" class="form-control-unit">{{ unit }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-4 d-flex flex-column justify-content-between">
        <div class="numberSlider-icon" v-html="fieldsData.svg"></div>
        <button class="btn btn-primary mt-4" @click="onSubmit">
          {{ $t("labels.next") }}
          <i class="fal fa-arrow-right ml-2"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FIELDS_DATA from "../fields";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";

export default {
  props: {
    fieldName: {
      type: String,
      required: true
    }
  },
  methods: {
    tooltipFormatter(val) {
      if (this.unit) {
        return `${val} ${this.unit}`;
      }
      return val;
    },
    onSubmit() {
      this.$store.commit("nextStep");
    }
  },
  components: {
    VueSlider
  },
  computed: {
    numbersField: {
      get() {
        return this.$store.state[this.fieldName];
      },
      set(value) {
        this.$store.commit("setValue", { fieldName: this.fieldName, value });
      }
    },
    fieldsData() {
      return FIELDS_DATA[this.fieldName];
    },
    min() {
      return this.fieldsData.min;
    },
    unit() {
      return this.fieldsData.unit;
    },
    max() {
      return this.fieldsData.max;
    }
  },
  mounted() {
    this.$refs.field && this.$refs.field.focus();
  }
};
</script>

<style>
.form-control-unit {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 0.45rem 0.75rem;
  color: #777777;
  z-index: 1;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.numberSlider-icon {
  display: none;
}

@media (min-width: 768px) {
  .numberSlider-icon {
    width: 75%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
  }
}
</style>
