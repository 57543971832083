<template>
  <div id="immo-valuation-app">
    <header
      class="sliderHeader"
      :class="{'sliderHeader--success': $store.getters.lastStepsReached}"
    >
      <button
        v-if="!$store.getters.lastStepsReached"
        :disabled="$store.state.activeStep < 0"
        @click="$store.commit('prevStep')"
        class="backButton"
      >
        <i class="fal fa-arrow-left"></i>
      </button>
      <h1 class="sliderHeader-title">{{ title }}</h1>
    </header>
    <div
      class="progressBar"
      :class="{'bg-success': $store.getters.percentageReached >= 1}"
      :style="progressBarStyle"
    ></div>
    <main class="sliderContent">
      <transition name="component-fade" mode="out-in">
        <component v-bind:is="view"></component>
      </transition>
    </main>
    <div class="overlay" v-if="loading"></div>
    <div class="overlay" v-if="error">
      <p class="text-danger">{{ $('site.error') }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import RsType from "./StepRsType";
import HouseType from "./StepHouseType";
import ApartmentType from "./StepApartmentType";
import LivingSpaceHouse from "./StepLivingSpaceHouse";
import LivingSpaceApartment from "./StepLivingSpaceApartment";
import PlotArea from "./StepPlotArea";
import Rooms from "./StepRooms";
import ConstructionYear from "./StepConstructionYear";
import Furnishing from "./StepFurnishing";
import Condition from "./StepCondition";
import Rented from "./StepRented";
import PlotDeveloped from "./StepPlotDeveloped";
import NetFloorSpace from "./StepNetFloorSpace";
import CommercialType from "./StepCommercialType";
import Reason from "./StepReason";
import FakeLoading from "./StepFakeLoading";
import ZipCode from "./StepZipCode";
import Contact from "./StepContact";
import Success from "./StepSuccess";

const customCSS = (color, lpCss) => `
  :root {
    --primary: ${color} !important;
  }
  .text-primary {
    color: ${color} !important;
  }
  .btn-primary {
    background-color: ${color};
    border: none;
  }
  .btn-primary:hover {
    background-color: ${color};
    box-shadow: 1px 0 7px 4px ${color}40;
  }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: ${color};
  }

  .form-control:focus {
    border-color: ${color}70;
    box-shadow: 1px 0 7px 4px ${color}40;
  }

  ${lpCss || ""}
`;

export default {
  name: "app",
  data() {
    return {
      loading: false,
      error: false
    };
  },
  components: {
    RsType,
    HouseType,
    ApartmentType,
    LivingSpaceHouse,
    LivingSpaceApartment,
    PlotArea,
    Rooms,
    ConstructionYear,
    Furnishing,
    Condition,
    Rented,
    PlotDeveloped,
    NetFloorSpace,
    CommercialType,
    Reason,
    FakeLoading,
    ZipCode,
    Contact,
    Success
  },
  computed: {
    view() {
      return this.$store.getters.activeStepComponent;
    },
    title() {
      return this.$t(`titles.${this.view}`);
    },
    progressBarStyle() {
      return {
        width: parseInt(this.$store.getters.percentageReached * 100) + "%"
      };
    }
  },
  mounted() {
    this.loading = true;
    axios
      .get(location.pathname)
      .then(({ data }) => {
        // console.log(data);
        this.$store.commit("setShopInfo", data.shop);
        if (data.shop.color) {
          const css = customCSS(data.shop.color, data.shop.lpCss),
            style = document.createElement("style");
          document.head.appendChild(style);
          style.appendChild(document.createTextNode(css));
        }
      })
      .catch(error => {
        console.error(error);
        this.error = true;
      })
      .finally(() => (this.loading = false));
  }
};
</script>

<style>
#immo-valuation-app {
  background: white;
  height: 450px;
  width: 100%;
  max-width: 770px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0 auto;
  overflow: auto;
}
.sliderHeader {
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid #dddddd;
  position: relative;
}
.sliderHeader--success {
  background: var(--success);
  border-bottom-color: transparent;
  color: white;
}
.sliderHeader-title {
  font-size: 0.75rem;
  margin: 0;
  font-weight: 400;
  padding: 0 50px;
}
.backButton {
  height: 26px;
  width: 26px;
  font-size: .75rem;
  border: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  padding: 0px;
  background: #f3f3f3;
  position: absolute;
  left: 1rem;
  border-radius: 0;
}
.backButton:hover {
  background: #dddddd;
}

.progressBar {
  position: relative;
  height: 3px;
  margin-top: -2px;
  background-color: var(--primary);
  transition: all 100ms ease-in-out;
}

.sliderContent {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0px 1rem;
}

@media (min-width: 768px) {
  .backButton {
    left: 40px;
    height: 32px;
    width: 32px;
    font-size: 1rem;
  }
  .sliderHeader {
    height: 66px;
  }
  .sliderHeader-title {
    font-size: 1.35rem;
  }
  .sliderContent {
    padding: 0px 40px;
  }
}
</style>