<template>
  <div id="furnishing">
    <option-buttons fieldName="furnishing" :on-click="clicked"></option-buttons>
    <div class="row justify-content-end mt-2">
      <div class="col-md-4 d-flex flex-column">
        <button @click="next" class="btn btn-primary">
          {{ $t('labels.next') }}
          <i class="fal fa-arrow-right ml-2"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    clicked(value) {
      const newValue = this.oldValue.includes(value)
        ? this.oldValue.filter(o => o != value)
        : this.oldValue.concat([value]);

      this.$store.commit("setValue", {
        fieldName: "furnishing",
        value: newValue
      });
    },
    next() {
      this.$store.commit("nextStep");
    }
  },
  computed: {
    oldValue() {
      return this.$store.state.furnishing || [];
    }
  }
};
</script>