<template>
  <div id="slide-contact">
    <p class="contact-headline" v-html="$t('contact.headline')"></p>
    <div class="row flex-grow-1">
      <div class="col-sm-7">
        <div class="mb-2">
          <select v-model="salutation" class="form-control">
            <option value="mr">{{ $t('contact.salutationMr') }}</option>
            <option value="ms">{{ $t('contact.salutationMs') }}</option>
          </select>
        </div>
        <div class="mb-2 d-flex">
          <input
            ref="firstName"
            type="text"
            :placeholder="$t('contact.firstName')"
            v-model="firstName"
            class="form-control mr-2"
          />
          <input
            type="text"
            :placeholder="$t('contact.lastName')"
            v-model="lastName"
            class="form-control"
          />
        </div>
        <div class="mb-2">
          <input
            type="email"
            :placeholder="$t('contact.email')"
            v-model="email"
            class="form-control"
          />
        </div>
        <div>
          <input type="tel" :placeholder="phonePlaceholder" v-model="phone" class="form-control" />
        </div>
      </div>
      <div class="col-sm d-flex justify-content-between flex-column">
        <div style="margin-top: -0.5rem;">
          <img src="../assets/booklet.png" class="w-100 mx-auto d-none d-sm-block contact-booklet" />
        </div>
      </div>
    </div>
    <div class="mt-2 checkbox-wrapper">
      <label v-if="$store.state.shopInfo.immoValuationConsentText" class="mb-1">
        <input type="checkbox" v-model="allowContact" />
        <span v-if="$i18n.locale == 'en'">
          I agree that my contact and property data will be processed in accordance with the
          <a
            href="#"
            @click.prevent="consentVisible = true"
          >consent</a> for property valuation and establishment of contact and that I may be contacted.
        </span>
        <span v-else>
          Ich bin damit einverstanden, dass meine Kontakt- und Objektdaten gemäß der
          <a
            href="#"
            @click.prevent="consentVisible = true"
          >Einwilligung</a> für die Objektanalyse und Kontaktaufnahme verarbeitet werden und ich kontaktiert werden darf.
        </span>
      </label>
      <label v-if="$store.state.shopInfo.immoValuationPrivacyUrl">
        <input type="checkbox" v-model="acceptPrivacy" />
        <span v-if="$i18n.locale == 'en'">
          I agree to the
          <a
            :href="$store.state.shopInfo.immoValuationPrivacyUrl"
            target="_blank"
            class="text-primary"
          >data protection declaration</a> (your data will be processed in accordance with Article 6 Paragraph 1 b GDPR).
        </span>
        <span v-else>
          Ich stimme der
          <a
            :href="$store.state.shopInfo.immoValuationPrivacyUrl"
            target="_blank"
            class="text-primary"
          >Datenschutzerklärung</a> zu (Ihre Daten werden gem. Artikel 6 Abs 1 b DSGVO verarbeitet.)
        </span>
      </label>
    </div>
    <div class="mt-2 contact-buttonWrapper">
      <button
        :disabled="!formCompleted || submitting"
        class="btn btn-primary w-100"
        @click="onSubmit"
      >
        <i
          :class="{'invisible': !submitting}"
          class="fal fa-spinner-third fa-spin mr-3"
          style="margin-left: -1rem;"
        ></i>
        {{ $t('contact.submit') }}
      </button>
      <p v-if="error" class="mt-2 text-danger">
        <small>{{ $t('contact.error') }}</small>
      </p>
    </div>

    <modal :visible.sync="consentVisible">
      <article>
        <h4>{{ $t('contact.consent') }}</h4>
        <p style="white-space: pre-wrap;">{{ $store.state.shopInfo.immoValuationConsentText }}</p>
      </article>
    </modal>
  </div>
</template>

<script>
import Modal from "./components/Modal.vue";
const EMAIL_REGEX = /^[-a-z0-9_+\.]+\@([-a-z0-9]+\.)+[a-z0-9]{2,20}$/i;

export default {
  data() {
    return {
      submitting: false,
      error: false,
      allowContact: false,
      acceptPrivacy: false,
      consentVisible: false
    };
  },
  components: {
    Modal
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      this.$store
        .dispatch("submit")
        .catch(error => {
          this.error = true;
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  },
  computed: {
    contact() {
      return this.$store.state.contact;
    },
    phonePlaceholder() {
      return (
        this.$t("contact.phone") + (!this.phoneRequired ? " (optional)" : "")
      );
    },
    consented() {
      return (
        this.allowContact ||
        !this.$store.state.shopInfo.immoValuationConsentText
      );
    },
    privacyAccepted() {
      return (
        this.acceptPrivacy ||
        !this.$store.state.shopInfo.immoValuationPrivacyUrl
      );
    },
    phoneRequired() {
      return this.$store.state.shopInfo.immoValuationPhoneRequired;
    },
    phoneNumberCompleted() {
      return (
        (this.contact.phone && this.contact.phone.length > 5) ||
        !this.phoneRequired
      );
    },
    formCompleted() {
      return (
        this.contact.salutation &&
        this.contact.firstName &&
        this.contact.lastName &&
        this.contact.email &&
        EMAIL_REGEX.test(this.contact.email) &&
        this.phoneNumberCompleted &&
        this.consented &&
        this.privacyAccepted
      );
    },
    salutation: {
      get() {
        return this.contact.salutation;
      },
      set(value) {
        this.$store.commit("updateContact", { field: "salutation", value });
      }
    },
    firstName: {
      get() {
        return this.contact.firstName;
      },
      set(value) {
        this.$store.commit("updateContact", { field: "firstName", value });
      }
    },
    lastName: {
      get() {
        return this.contact.lastName;
      },
      set(value) {
        this.$store.commit("updateContact", { field: "lastName", value });
      }
    },
    email: {
      get() {
        return this.contact.email;
      },
      set(value) {
        this.$store.commit("updateContact", { field: "email", value });
      }
    },
    phone: {
      get() {
        return this.contact.phone;
      },
      set(value) {
        this.$store.commit("updateContact", { field: "phone", value });
      }
    }
  },
  mounted() {
    this.$refs.firstName && this.$refs.firstName.focus();
  }
};
</script>

<style scoped>
.checkbox-wrapper {
  font-size: 67%;
  font-weight: 300;
}
.contact-headline {
  font-size: 0.8rem;
}
@media (min-width: 768px) {
  .checkbox-wrapper {
    font-size: 80%;
  }
  .contact-headline {
    font-size: 1rem;
  }
}
</style>
