<template>
  <div v-show="visible" class="vmodal">
    <a href="#" @click.prevent="$emit('update:visible', false)" class="vmodal-close">
      <i class="fal fa-times"></i>
    </a>
    <div class="vmodal-content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>
.vmodal {
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.vmodal-close {
  position: absolute;
  top: .5rem;
  right: 1rem;
  font-size: 2rem;
  color: black;
}

.vmodal-content {
  padding: 1.5rem;
  height: 100%;
  overflow: auto;
}
</style>
