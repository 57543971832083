import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

let sampleData = {};

if (false) {
  sampleData = {
    street: "Münzstraße",
    houseNumber: "12",
    zipCode: "10178",
    city: "Berlin",
    salutation: "mr",
    firstName: "Test",
    lastName: "Immowerter",
    email: "test@immo-werter.de",
    phone: "123 456 789"
  };
}

const LAST_STEPS = ["reason", "zip-code", "fake-loading", "contact", "success"];
const STEP_TREE = {
  trade_site: ["plot-area", "plot-developed", ...LAST_STEPS],
  house: [
    "house-type",
    "living-space-house",
    "plot-area",
    "rooms",
    "furnishing",
    "construction-year",
    "condition",
    "rented",
    ...LAST_STEPS
  ],
  apartment: [
    "apartment-type",
    "living-space-apartment",
    "rooms",
    "furnishing",
    "construction-year",
    "condition",
    "rented",
    ...LAST_STEPS
  ],
  commercial: [
    "commercial-type",
    "net-floor-space",
    "plot-area",
    "construction-year",
    ...LAST_STEPS
  ]
};

export default new Vuex.Store({
  state: {
    shopInfo: {},
    activeStep: -1,
    rsType: null,
    houseType: null,
    apartmentType: null,
    commercialType: null,
    plotDeveloped: null,
    livingSpaceHouse: 120,
    livingSpaceApartment: 80,
    rooms: 3,
    plotArea: 500,
    netFloorSpace: 100,
    constructionYear: 1960,
    furnishing: [],
    condition: null,
    rented: null,
    reason: null,
    street: sampleData.street,
    houseNumber: sampleData.houseNumber,
    zipCode: sampleData.zipCode,
    city: sampleData.city,
    countryCode: sampleData.countryCode,
    contact: {
      salutation: sampleData.salutation || 'mr',
      firstName: sampleData.firstName,
      lastName: sampleData.lastName,
      email: sampleData.email,
      phone: sampleData.phone
    },
    submitting: false,
    valuationLowerRange: null,
    valuationUpperRange: null,
    valuationCurrency: null,
    valuationConfidence: null
  },
  getters: {
    activeStepComponent: state => {
      if (
        STEP_TREE[state.rsType] &&
        STEP_TREE[state.rsType][state.activeStep]
      ) {
        return STEP_TREE[state.rsType][state.activeStep];
      } else {
        return "rs-type";
      }
    },
    lastStepsReached: state => {
      return (
        STEP_TREE[state.rsType] &&
        state.activeStep >= STEP_TREE[state.rsType].length - 2
      );
    },
    percentageReached: state => {
      if (!STEP_TREE[state.rsType]) return 0;
      return Math.min(
        1,
        (parseFloat(state.activeStep) + 1) /
          (STEP_TREE[state.rsType].length - 2)
      );
    }
  },
  mutations: {
    setValue(state, { fieldName, value }) {
      state[fieldName] = value;
    },
    setRsType(state, value) {
      state.rsType = value;
    },
    updatePlotArea(state, value) {
      state.plotArea = value;
    },
    updateStreet(state, value) {
      state.street = value;
    },
    updateHouseNumber(state, value) {
      state.houseNumber = value;
    },
    updateZipCode(state, value) {
      state.zipCode = value;
    },
    updateCity(state, value) {
      state.city = value;
    },
    updateCountryCode(state, value) {
      state.countryCode = value;
    },
    nextStep(state) {
      state.activeStep++;
    },
    prevStep(state) {
      state.activeStep--;
    },
    updateContact(state, { field, value }) {
      state.contact[field] = value;
    },
    setSubmitting(state, value) {
      state.submitting = value;
    },
    setShopInfo(state, value) {
      state.shopInfo = value;
    },
    setValuationInfos(state, data) {
      state.valuationLowerRange = data.valuationLowerRange;
      state.valuationUpperRange = data.valuationUpperRange;
      state.valuationCurrency = data.valuationCurrency;
      state.valuationConfidence = data.valuationConfidence;
    }
  },
  actions: {
    submit({ state, commit }) {
      return axios
        .post(location.pathname, {
          rsType: state.rsType,
          houseType: state.houseType,
          apartmentType: state.apartmentType,
          commercialType: state.commercialType,
          plotDeveloped: state.plotDeveloped,
          livingSpaceHouse: state.livingSpaceHouse,
          livingSpaceApartment: state.livingSpaceApartment,
          plotArea: state.plotArea,
          rooms: state.rooms,
          netFloorSpace: state.netFloorSpace,
          constructionYear: state.constructionYear,
          condition: state.condition,
          furnishing: state.furnishing,
          rented: state.rented == "rented",
          reason: state.reason,
          street: state.street,
          houseNumber: state.houseNumber,
          zipCode: state.zipCode,
          city: state.city,
          countryCode: state.countryCode,
          contact: state.contact
        })
        .then(response => {
          commit("nextStep");
          commit("setValuationInfos", response.data);
        });
    }
  }
});
