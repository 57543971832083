import "./style.scss";
import Vue from "vue";
import VueI18n from "vue-i18n";
import "es6-promise/auto"; // braucht vuex, wenn wir IE unterstützen wollen
import store from "./src/store";
import App from "./src/App.vue";
import OptionButtons from "./src/components/OptionButtons";
import OptionButton from "./src/components/OptionButton";
import NumberSlider from "./src/components/NumberSlider";
import messages from "./locales";

Vue.use(VueI18n);

Vue.component("option-buttons", OptionButtons);
Vue.component("option-button", OptionButton);
Vue.component("number-slider", NumberSlider);

const i18n = new VueI18n({
  locale: document.documentElement.lang || "de",
  fallbackLocale: "de",
  messages
});

new Vue({
  el: "#immo-valuation-app",
  i18n,
  store,
  render: h => h(App)
});
