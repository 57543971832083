<template>
  <div class="text-center w-100">
    <p class="display-1 text-muted fa-spin mr-5 mb-0">
      <i class="fal fa-cog"></i>
    </p>
    <p class="display-1 text-muted fa-spin ml-5">
      <i class="fal fa-cog"></i>
    </p>
  </div>
</template>

<script>
export default {
  mounted() {
    setTimeout(() => {
      this.$store.commit('nextStep')
    }, 1500);
  }
}
</script>

<style scoped>

</style>
