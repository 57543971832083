export default {
  svgs: {
    trade_site: require("../assets/icons/trade_site.svg"),
    house: require("../assets/icons/house.svg"),
    apartment: require("../assets/icons/apartment.svg"),
    commercial: require("../assets/icons/commercial.svg"),
    single_family_house: require("../assets/icons/single_family_house.svg"),
    multi_family_house: require("../assets/icons/multi_family_house.svg"),
    terrace_house: require("../assets/icons/terrace_house.svg"),
    semidetached_house: require("../assets/icons/semidetached_house.svg"),
    ground_floor: require("../assets/icons/ground_floor.svg"),
    roof_storey: require("../assets/icons/roof_storey.svg"),
    half_basement: require("../assets/icons/souterrain.svg"),
    loft: require("../assets/icons/loft.svg"),
    maisonette: require("../assets/icons/maisonette.svg"),
    vacant: require("../assets/icons/bezugsfrei.svg"),
    rented: require("../assets/icons/vermietet.svg"),
    lift: require("../assets/icons/aufzug.svg"),
    guest_toilet: require("../assets/icons/gaeste-wc.svg"),
    parking_space: require("../assets/icons/stellplatz.svg"),
    garden: require("../assets/icons/garten.svg"),
    cellar: require("../assets/icons/keller.svg"),
    balcony: require("../assets/icons/terrasse-balkon.svg"),
    living_business: require("../assets/icons/living_business.svg"),
    office: require("../assets/icons/office.svg"),
    other_commercial: require("../assets/icons/other_commercial.svg"),
    industry: require("../assets/icons/industry.svg"),
    undeveloped: require("../assets/icons/undeveloped.svg"),
    partially_developed: require("../assets/icons/partially_developed.svg"),
    developed: require("../assets/icons/developed.svg"),
    simple: require("../assets/icons/simple.svg"),
    normal: require("../assets/icons/normal.svg"),
    luxury: require("../assets/icons/luxury.svg"),
    sell: require("../assets/icons/sell.svg"),
    buy: require("../assets/icons/buy.svg"),
    other: require("../assets/icons/other.svg"),
    need_of_renovation: require("../assets/icons/need_of_renovation.svg"),
    well_kept: require("../assets/icons/normal.svg"),
    mint_condition: require("../assets/icons/mint_condition.svg"),
  },

  plotArea: {
    min: 200,
    max: 2500,
    unit: "m²",
    svg: require("../assets/icons/plot-area.svg")
  },
  livingSpaceHouse: {
    min: 50,
    max: 500,
    unit: "m²",
    svg: require("../assets/icons/living-space.svg")
  },
  livingSpaceApartment: {
    min: 20,
    max: 200,
    unit: "m²",
    svg: require("../assets/icons/living-space.svg")
  },
  netFloorSpace: {
    min: 50,
    max: 500,
    unit: "m²",
    svg: require("../assets/icons/living-space.svg")
  },
  rooms: {
    min: 1,
    max: 6,
    unit: false,
    svg: require("../assets/icons/rooms.svg")
  },
  constructionYear: {
    min: 1900,
    max: new Date().getFullYear(),
    unit: false,
    svg: require("../assets/icons/construction-year.svg")
  }
};
